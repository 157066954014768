@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-facebook: "\EA01"
$icon-youtube: "\EA02"
$icon-telegram: "\EA03"
$icon-linkedin: "\EA04"
$icon-twitter: "\EA05"
$icon-bage: "\EA06"
$icon-arrow: "\EA07"
$icon-marker: "\EA08"
$icon-chevron-right: "\EA09"
$icon-chevron-left: "\EA10"
$icon-left-chevron: "\EA11"
$icon-right-chevron: "\EA12"
$icon-mark: "\EA13"
$icon-phone: "\EA14"
$icon-mail: "\EA15"
$icon-faks: "\EA16"
$icon-404: "\EA17"
$icon-share: "\EA18"
$icon-layer: "\EA19"
$icon-print: "\EA20"
$icon-geolocation: "\EA21"
$icon-zoom-in: "\EA22"
$icon-zoom-out: "\EA23"
$icon-search: "\EA24"
$icon-dropdown: "\EA25"
$icon-measure: "\EA26"
$icon-close: "\EA27"
$icon-info: "\EA28"
$icon-route: "\EA29"
$icon-copy: "\EA30"
$icon-dots: "\EA31"
$icon-cart: "\EA32"
$icon-notification: "\EA33"
$icon-logout: "\EA34"
$icon-catalog: "\EA35"
$icon-users: "\EA36"
$icon-requests: "\EA37"
$icon-settings: "\EA38"
$icon-plus: "\EA39"
$icon-arrow-left: "\EA40"
$icon-add: "\EA41"
$icon-options: "\EA42"
$icon-eye: "\EA43"
$icon-bookmark: "\EA44"
$icon-viber: "\EA45"
$icon-whatsapp: "\EA46"
$icon-link: "\EA47"
$icon-triden: "\EA48"
$icon-arrow-right: "\EA49"
$icon-eyes1: "\EA50"
$icon-eyes2: "\EA51"
$icon-errorLarge: "\EA52"
$icon-update: "\EA53"
$icon-filter: "\EA54"
$icon-edit: "\EA55"
$icon-load: "\EA56"
$icon-plus-2: "\EA57"
$icon-arrow-left-2: "\EA58"
$icon-file: "\EA59"
$icon-time: "\EA60"
$icon-calendar: "\EA61"
$icon-target: "\EA62"
$icon-clear-filter: "\EA63"
$icon-location: "\EA64"
$icon-plus-sm: "\EA65"
$icon-percent: "\EA66"
$icon-area: "\EA67"
$icon-holdings: "\EA68"
$icon-checkmark: "\EA69"
$icon-message: "\EA70"
$icon-edit2: "\EA71"
$icon-photo: "\EA72"
$icon-color: "\EA73"
$icon-chat: "\EA74"
$icon-list: "\EA75"
$icon-star-empty: "\EA76"
$icon-star-filled: "\EA77"
$icon-unlock: "\EA78"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-facebook:before
	content: $icon-facebook
.icon-youtube:before
	content: $icon-youtube
.icon-telegram:before
	content: $icon-telegram
.icon-linkedin:before
	content: $icon-linkedin
.icon-twitter:before
	content: $icon-twitter
.icon-bage:before
	content: $icon-bage
.icon-arrow:before
	content: $icon-arrow
.icon-marker:before
	content: $icon-marker
.icon-chevron-right:before
	content: $icon-chevron-right
.icon-chevron-left:before
	content: $icon-chevron-left
.icon-left-chevron:before
	content: $icon-left-chevron
.icon-right-chevron:before
	content: $icon-right-chevron
.icon-mark:before
	content: $icon-mark
.icon-phone:before
	content: $icon-phone
.icon-mail:before
	content: $icon-mail
.icon-faks:before
	content: $icon-faks
.icon-404:before
	content: $icon-404
.icon-share:before
	content: $icon-share
.icon-layer:before
	content: $icon-layer
.icon-print:before
	content: $icon-print
.icon-geolocation:before
	content: $icon-geolocation
.icon-zoom-in:before
	content: $icon-zoom-in
.icon-zoom-out:before
	content: $icon-zoom-out
.icon-search:before
	content: $icon-search
.icon-dropdown:before
	content: $icon-dropdown
.icon-measure:before
	content: $icon-measure
.icon-close:before
	content: $icon-close
.icon-info:before
	content: $icon-info
.icon-route:before
	content: $icon-route
.icon-copy:before
	content: $icon-copy
.icon-dots:before
	content: $icon-dots
.icon-cart:before
	content: $icon-cart
.icon-notification:before
	content: $icon-notification
.icon-logout:before
	content: $icon-logout
.icon-catalog:before
	content: $icon-catalog
.icon-users:before
	content: $icon-users
.icon-requests:before
	content: $icon-requests
.icon-settings:before
	content: $icon-settings
.icon-plus:before
	content: $icon-plus
.icon-arrow-left:before
	content: $icon-arrow-left
.icon-add:before
	content: $icon-add
.icon-options:before
	content: $icon-options
.icon-eye:before
	content: $icon-eye
.icon-bookmark:before
	content: $icon-bookmark
.icon-viber:before
	content: $icon-viber
.icon-whatsapp:before
	content: $icon-whatsapp
.icon-link:before
	content: $icon-link
.icon-triden:before
	content: $icon-triden
.icon-arrow-right:before
	content: $icon-arrow-right
.icon-eyes1:before
	content: $icon-eyes1
.icon-eyes2:before
	content: $icon-eyes2
.icon-errorLarge:before
	content: $icon-errorLarge
.icon-update:before
	content: $icon-update
.icon-filter:before
	content: $icon-filter
.icon-edit:before
	content: $icon-edit
.icon-load:before
	content: $icon-load
.icon-plus-2:before
	content: $icon-plus-2
.icon-arrow-left-2:before
	content: $icon-arrow-left-2
.icon-file:before
	content: $icon-file
.icon-time:before
	content: $icon-time
.icon-calendar:before
	content: $icon-calendar
.icon-target:before
	content: $icon-target
.icon-clear-filter:before
	content: $icon-clear-filter
.icon-location:before
	content: $icon-location
.icon-plus-sm:before
	content: $icon-plus-sm
.icon-percent:before
	content: $icon-percent
.icon-area:before
	content: $icon-area
.icon-holdings:before
	content: $icon-holdings
.icon-checkmark:before
	content: $icon-checkmark
.icon-message:before
	content: $icon-message
.icon-edit2:before
	content: $icon-edit2
.icon-photo:before
	content: $icon-photo
.icon-color:before
	content: $icon-color
.icon-chat:before
	content: $icon-chat
.icon-list:before
	content: $icon-list
.icon-star-empty:before
	content: $icon-star-empty
.icon-star-filled:before
	content: $icon-star-filled
.icon-unlock:before
	content: $icon-unlock
